<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-btn
          color="primary"
          fab
          dark
          to="/pages/produtos/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card class="mb-4">
          <v-col cols="12">
            <v-form @submit.prevent="load">
              <v-row class="p-2">
                <v-col
                  cols="6"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="filter.Nome"
                    label="Nome"
                    required
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="filter.Referencia"
                    label="Referência"
                    required
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="3"
                >
                  <v-switch
                    v-model="filter.somentePromocao"
                    label="Somente promoção?"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="3"
                >
                  <v-switch
                    v-model="filter.oportunidade"
                    label="Somente oportunidades?"
                  />
                </v-col>
                <v-col
                  cols="3"
                  sm="3"
                  md="3"
                >
                  <v-btn
                    color="primary"
                    style="margin-top: 16px;"
                    @click="load"
                  >
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="produtos"
          :options.sync="options"
          :server-items-length="total"
          class="elevation-1"
          :footer-props="footer_props"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
              >
                <td>{{ item.nome }}</td>
                <td>{{ item.referencia }}</td>
                <td>{{ item.todasCategorias }}</td>
                <td>
                  <router-link :to="`/pages/produtos/${item.id}`">
                    <v-icon
                      class="mr-2"
                      color="info"
                    >
                      mdi-pencil
                    </v-icon>
                  </router-link>
                  <v-icon
                    color="error"
                    @click="destroy(item)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          O produto será deletado. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialog = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  export default {
    name: 'ProdutoList',
    data: () => ({
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      itemselected: {},
      produtos: [],
      headers: [
        { text: 'Nome', sortable: false },
        { text: 'Referência', sortable: false },
        { text: 'Categorias', sortable: false },
        { text: 'Ações', value: 'action', sortable: false },
      ],
      total: 0,
      dialog: false,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        IncluirEstoqueZerado: true,
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load: function () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        const query = JSON.stringify(this.filter)
        this.$http.get('/produtos', { params: { query } })
          .then(({ data }) => {
            this.produtos = data.rows
            this.total = data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      destroy (item) {
        this.itemselected = item
        this.dialog = true
      },
      confirmDelete () {
        this.$http.delete(`/produtos/${this.itemselected.id}`)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.dialog = false
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>

<style>
a {
  text-decoration: none;
}
</style>
